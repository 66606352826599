import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate, 
		useParams, } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, DropdownMenu, Tooltip,
	TabNav, TextArea, Callout,
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import ReactMarkdown from 'react-markdown';

import { HatoIcon, QnAIcon, CircleIcon, JobIcon, DocIcon,
	NotebookIcon, PageIcon,
	} from './Icons';

import { EnterIcon, ClipboardIcon, ImageIcon, 
	EyeNoneIcon, EyeOpenIcon, DropdownMenuIcon, HamburgerMenuIcon,
	MoonIcon, LayersIcon, SunIcon, ExitIcon, PersonIcon,
	PlusIcon, DashboardIcon, Cross1Icon, DotsVerticalIcon,
	ComponentInstanceIcon, ReaderIcon, CommitIcon,
	HeartIcon, HeartFilledIcon, DotFilledIcon,
	MagnifyingGlassIcon, DoubleArrowDownIcon,
	InfoCircledIcon, ResetIcon, ShuffleIcon, ChevronRightIcon,
	TriangleRightIcon, TriangleDownIcon,
	TrackNextIcon, TrackPreviousIcon,
	} from '@radix-ui/react-icons'

import './Circles.css';
import {server_post, notify, Content, Logo, MkRefresh, Scrollable,
	DateStr, TabHeading, first_name, anon_show, randn,
	ShowJson, cap, AiFetchPoll, SpeechToText, 
	HighlightedText, json_mean, SearchBar, goto_nb,
	} from './Lib';

import {ListNotebooks} from './Notebooks';

function CircleHead({cid, refresh, search}) {
	const root = window.my;
	const circle = root.circles[cid];

	// console.log('CircleHead', {circle});

	return (
<Link to={`/circles/${cid}`} className="circle" >
<Box p="2" my="4" data-did={circle.id} >
<Flex justify="between" align="center" >
	<HatoIcon />
	<Flex flexGrow="1" px="2">
		{circle.name}
	</Flex>
	<Flex gap="2">
	  {circle.is_favorite?  
		<HeartFilledIcon style={{ color:'var(--indigo-8)' }} /> : null }
		<ChevronRightIcon />
	</Flex>
</Flex>
</Box>
</Link>
	);
}

function ListCircles({search}) {
	if (!window.my.id)
		return null;
	
	const root = window.my;
	const circles = root.circles;
	
	const narrow = cid => {
		const circle = root.circles[cid];

		if (search.fav && !circle.is_favorite)
			return false;

		if (search.text) {
			const regexp = new RegExp(search.text, 'i');
			if (!regexp.test(circle.name))
				return false;
		}

		return true;
	}

	const cids = Object.keys(circles)
			.filter(cid => narrow(cid))
			.map(Number)
			.sort((a,b) => circles[a].name.localeCompare(
					circles[b].name));

	const refresh = MkRefresh();

	return (
	<>
	{cids.map(cid => <CircleHead cid={cid}
		key={cid} 
		refresh={refresh}
		search={search}
		/>)}
	</>
	);
}

//----------

function Welcome() {
	// if (Object.keys(window.my.dreams).length)
		// return null;

	return (
<Card style={{ background: '#eeffee' }}>
  <Heading size="3" align="center">
    Welcome
  </Heading>

  <Text>
    <ol>
      <li> Hire Hato</li>
      <li> Give them jobs</li>
      <li> Guide them </li>
      <li> Get results </li>
    </ol>

    <Text as="p" align="center">
      Use the buttons at the top
    </Text>
  </Text>
</Card>
	);
}

//----------

function ShowCircle() {
	const [search, setSearch] = useState({text: '', fav: false});
	const [scrollRef, setScrollRef] = useState(null);
	const [info, setInfo] = useState(false);
	const [autoAdd, setAutoAdd] = useState(true);
	const { cid, nid } = useParams();
	const [curnb, setCurnb2] = useState(nid === undefined ? -1 : nid);
	const refresh = MkRefresh();
	const navigate = useNavigate();

	const root = window.my;
	const circle = root.circles[cid];

	const setCurnb = id =>  {
		setCurnb2(id);
		goto_nb(navigate, cid, id);
	}

	useEffect( () => {
		window.my.handlers.refreshCircle = refresh;
	}, []);

	const add = async (e) => {
		e && e.preventDefault();

		let data = null, error = '';
		const text='';

		data = await server_post('/api/notebook/add/', {cid});

		if (data) {
			const nid = Object.keys(data)[0];
			const my = window.my;
			my.circles[cid].notebooks[nid] = data[nid];
			my.current.nid = nid;
			setCurnb(nid);
		}
	}

	const toggleInfo = async (e) => {
		setInfo(!info);
	}

	const toggleFav = async (e) => {
		const mid =  circle.member_id;
		const data = await server_post(
			`/api/circle/${mid}/toggle_favorite/`,
				{},
				);

		if (data) {
			circle.is_favorite = data.is_favorite;
			refresh();
		}
	}

	console.log('ShowCircle', cid);

	return (
	<>
<Content >
    <Flex align="center" justify="between" mt="2" >

      <TabHeading scrollRef={scrollRef}>
<Flex align="center" >
	<HatoIcon />
	<Text mx="2">
	       @{circle.handle}
	</Text>
</Flex>
      </TabHeading>

<Flex gap="2" >
        <IconButton variant="soft" onClick={toggleInfo} >
	  <InfoCircledIcon />
	</IconButton>

        <IconButton variant="soft" onClick={toggleFav} >
	  {circle.is_favorite?  <HeartFilledIcon /> : <HeartIcon />}
	</IconButton>

<Link to={`/circles/`} >
        <IconButton variant="soft" >
	  <Cross1Icon />
	</IconButton>
</Link>
</Flex>

    </Flex>

{info && <Box my="2" >
<Card style={{ background: 'var(--lime-3)' }} >
	<Heading size="1" my="1" >
		About @{circle.handle}: ({circle.name})
	</Heading>

	<Text>
		{circle.description}
	</Text>
</Card>
</Box>}

</Content>
<Scrollable setScrollRef={setScrollRef} 
	>
<Content>
  <Flex mb="2" >
	<Heading size="2" my="1" >
		Notebooks
	</Heading>
        <IconButton variant="soft" onClick={add} mx="2" >
	  <PlusIcon />
	</IconButton>
      <SearchBar search={search} setSearch={setSearch} />
  </Flex>
  <Card>

    <ListNotebooks cid={cid} search={search} 
    	curnb={curnb} setCurnb={setCurnb} 
	addNotebook={add}
	/>
    
  </Card>
</Content>
</Scrollable>
	</>
	);
}

//----------

function Circles() {
	const settings = window.my.current.settings;
	const [search, setSearch] = useState({
		text: '', fav: settings.showFavCircles,
	});
	const [scrollRef, setScrollRef] = useState(null);

	const refresh = MkRefresh();

	settings.showFavCircles = search.fav;


	return (
	<>
<Content >
    <Flex align="center" justify="between" m="2" >

      <TabHeading scrollRef={scrollRef}>
       My Hato
      </TabHeading>

      <SearchBar search={search} setSearch={setSearch} />

    </Flex>
</Content>
<Scrollable setScrollRef={setScrollRef} 
	>
<Content>
  <Card>
    {/*
    <AddDream refresh={refresh} search={search}  />
    <Welcome />
    <ListDreams mine={true} search={search} />
    */}
    <ListCircles search={search} />

    
  </Card>
</Content>
</Scrollable>
	</>
	);
}


export {Circles, ShowCircle};
